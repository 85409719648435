import { Text } from '@mantine/core';

import { SideContent } from '@components/SideContent';

import { usePreferences } from '../api/getPreferences';

import { SettingsForm } from './SettingsForm';

export const SettingsPage = () => {
  const { isLoading: isLoadingPreferences, data: preferences } = usePreferences();

  if (isLoadingPreferences || !preferences) {
    return null;
  }

  return (
    <SideContent
      sideContent={
        <Text c="dimmed">
          Add your preferences. We will factor in your selections when we schedule meetings for you.
        </Text>
      }
    >
      <SettingsForm initialPreferences={preferences} />
    </SideContent>
  );
};
