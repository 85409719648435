import { FC } from 'react';

import { Divider, Stack, Text } from '@mantine/core';
import { useLocation } from 'react-router-dom';

import { ROUTE_DETAILS } from '@routes/constants';
import { getActiveLink } from '@utils/getActiveLink';

import { NavLinkGroup } from './NavLinkGroup';

interface NavLinksProps {
  isCollapsed?: boolean;
  onNavigate?: () => void;
}

export const NavLinks: FC<NavLinksProps> = ({ isCollapsed, onNavigate }) => {
  const location = useLocation();
  const activeLink = getActiveLink(
    ROUTE_DETAILS.map((details) => details.path),
    location.pathname,
  );

  const groupedRoutes = ROUTE_DETAILS.filter((details) => !details.hidden).reduce(
    (acc, details) => {
      if (!details.subSection) {
        return acc;
      }

      if (!acc[details.subSection]) {
        acc[details.subSection] = [];
      }
      acc[details.subSection].push(details);
      return acc;
    },
    {} as Record<string, typeof ROUTE_DETAILS>,
  );

  const routesWithoutSubSection = ROUTE_DETAILS.filter((details) => !details.subSection);

  return (
    <Stack gap="xs">
      <NavLinkGroup
        routes={routesWithoutSubSection}
        isCollapsed={isCollapsed}
        activeLink={activeLink}
        onNavigate={onNavigate}
      />
      {Object.entries(groupedRoutes).map(([subSection, routes]) => (
        <Stack key={subSection} gap={isCollapsed ? 'xs' : '0'}>
          {isCollapsed ? (
            <Divider mb="xs" />
          ) : (
            <Text c="dimmed" fw={700} tt="uppercase" size="xs">
              {subSection}
            </Text>
          )}
          <NavLinkGroup routes={routes} isCollapsed={isCollapsed} activeLink={activeLink} onNavigate={onNavigate} />
        </Stack>
      ))}
    </Stack>
  );
};
