import { FC, useState } from 'react';

import { Group, Select, Stack, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconBell } from '@tabler/icons-react';

import { Switch } from '@components/Switch';
import { AnalyticsEventName, track } from '@features/analytics';

import { useUpdatePreferences } from '../api/updatePreferences';
import { PreferenceSettings } from '../types';

import { SettingsSectionTitle } from './SettingsSectionTitle';

interface MeetingNudgeProps {
  initialPreferences: PreferenceSettings;
}

export const MeetingNudge: FC<MeetingNudgeProps> = ({ initialPreferences }) => {
  const { mutate: updatePreferences } = useUpdatePreferences({
    onSuccess: () => {
      track({
        type: AnalyticsEventName.PreferencesUpdated,
        data: { preference: 'meetingNudge', isDefault: initialPreferences.isDefault },
      });
      notifications.show({ message: 'Nudge settings updated', color: 'green' });
    },
  });

  const [meetingNudgeEnabled, setMeetingNudgeEnabled] = useState(initialPreferences.nudgeEnabled);
  const [numDaysToNudge, setNumDaysToNudge] = useState(initialPreferences.numDaysToNudge);
  const [maxTimesToNudge, setMaxTimesToNudge] = useState(initialPreferences.maxTimesToNudge);

  const handleMaxTimesToNudgeChange = (value: string | null) => {
    if (!value) return;

    const newMaxTimes = parseInt(value);

    setMaxTimesToNudge(newMaxTimes);
    updatePreferences({
      ...initialPreferences,
      maxTimesToNudge: newMaxTimes,
    });
  };

  const handleNumDaysToNudgeChange = (value: string | null) => {
    if (!value) return;

    const newNumDaysToNudge = parseInt(value);

    setNumDaysToNudge(newNumDaysToNudge);
    updatePreferences({
      ...initialPreferences,
      numDaysToNudge: newNumDaysToNudge,
    });
  };

  const handleMeetingNudgeEnabled = () => {
    const newValue = !meetingNudgeEnabled;
    setMeetingNudgeEnabled(newValue);

    updatePreferences({
      ...initialPreferences,
      nudgeEnabled: newValue,
    });
  };

  const textColor = meetingNudgeEnabled ? undefined : 'gray.4';

  return (
    <Stack>
      <Group>
        <SettingsSectionTitle
          title="Meeting Nudge"
          Icon={IconBell}
          description="Set whether Blockit should follow up when someone is unresponsive."
        />
        <Switch checked={meetingNudgeEnabled} onChange={handleMeetingNudgeEnabled} offLabel="OFF" onLabel="ON" />
      </Group>

      <span>
        <Text span c={textColor}>
          Blockit should wait
        </Text>
        <Select
          display={'inline-block'}
          data={[1, 2, 3, 4, 5, 6, 7].map((numDay) => numDay.toString())}
          value={numDaysToNudge.toString()}
          onChange={handleNumDaysToNudgeChange}
          allowDeselect={false}
          pl={'xs'}
          pr={'xs'}
          w={80}
          disabled={!meetingNudgeEnabled}
          // Making disabled state lighter than normal to support "off" states in preferences
          styles={{
            input: {
              '--input-disabled-color': 'var(--mantine-color-gray-5)',
              '--input-disabled-bg': 'var(--mantine-color-gray-0)',
            },
          }}
        />
        <Text span c={textColor}>
          day{numDaysToNudge === 1 ? '' : 's'} to follow up on the thread and do it max
        </Text>
        <Select
          display={'inline-block'}
          data={[1, 2, 3, 4, 5].map((numDay) => numDay.toString())}
          value={maxTimesToNudge.toString()}
          onChange={handleMaxTimesToNudgeChange}
          allowDeselect={false}
          pl={'xs'}
          pr={'xs'}
          w={80}
          disabled={!meetingNudgeEnabled}
          // Making disabled state lighter than normal to support "off" states in preferences
          styles={{
            input: {
              '--input-disabled-color': 'var(--mantine-color-gray-5)',
              '--input-disabled-bg': 'var(--mantine-color-gray-0)',
            },
          }}
        />
        <Text span c={textColor}>
          time{maxTimesToNudge === 1 ? '' : 's'}.
        </Text>
      </span>
    </Stack>
  );
};
