import { Divider, Stack, Text } from '@mantine/core';
import { IconBrandZoom, IconCalendar } from '@tabler/icons-react';

import { SideContent } from '@components/SideContent';
import { TitleWithIcon } from '@components/TitleWithIcon';
import { useIsInternalUser } from '@features/auth/hooks/useIsInternalUser';
import { CalendarSettingsContainer } from '@features/calendar-selection';

import { ZoomIntegrationContainer } from './ZoomIntegrationContainer';

export const IntegrationsPage = () => {
  const isInternalUser = useIsInternalUser();
  return (
    <SideContent sideContent={<Text c="dimmed">Connect your calendars and conferencing tools to Blockit.</Text>}>
      <Stack>
        <TitleWithIcon title="Calendars" icon={<IconCalendar size={32} />} order={2} />
        <CalendarSettingsContainer />
        {isInternalUser && (
          <>
            <Divider />
            <TitleWithIcon title="Zoom" icon={<IconBrandZoom size={32} />} order={2} />
            <ZoomIntegrationContainer />
          </>
        )}
      </Stack>
    </SideContent>
  );
};
