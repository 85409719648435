import { useEffect, useState } from 'react';

import { Button, Radio, Select, Stack, Text, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { IconDeviceDesktop } from '@tabler/icons-react';
import { z } from 'zod';

import { AnalyticsEventName, track } from '@features/analytics';
import { useIsInternalUser } from '@features/auth/hooks/useIsInternalUser';
import { flipObject } from '@utils/flipObject';

import { useUpdatePreferences } from '../api/updatePreferences';
import { LinkType, PreferenceSettings, RemoteMeetingType } from '../types';

import { SettingsGrid } from './SettingsGrid';
import { ZoomAccountSelector } from './ZoomAccountSelector';

const DISPLAY_NAME_TO_LINK_TYPE: Record<string, LinkType> = {
  'Custom Link': LinkType.CustomLink,
  'Provider Defaults (Google Meet, Teams, etc.)': LinkType.GoogleMeet,
  Zoom: LinkType.Zoom,
};

const LINK_TYPE_TO_DISPLAY_NAME = flipObject(DISPLAY_NAME_TO_LINK_TYPE);

export const VirtualMeetings = ({ initialPreferences }: { initialPreferences: PreferenceSettings }) => {
  const isInternalUser = useIsInternalUser();

  const { mutate: updatePreferences, isPending: isUpdatingPreferences } = useUpdatePreferences({
    onSuccess: () => {
      track({
        type: AnalyticsEventName.PreferencesUpdated,
        data: { preference: 'linkType', isDefault: initialPreferences.isDefault },
      });
      notifications.show({ message: 'Virtual meeting preferences updated', color: 'green', autoClose: 3000 });
      form.resetDirty();
    },
  });
  const [linkType, setLinkType] = useState<LinkType | null>(null);
  const [remoteMeetingType, setRemoteMeetingType] = useState<RemoteMeetingType | null>(null);

  // Setting link type to the link type on preferences on initial load
  useEffect(() => {
    if (initialPreferences.linkType && !linkType) {
      setLinkType(initialPreferences.linkType);
    }
    if (initialPreferences.defaultRemoteMeetingType && !remoteMeetingType) {
      setRemoteMeetingType(initialPreferences.defaultRemoteMeetingType);
    }
  }, [initialPreferences.linkType, linkType, initialPreferences.defaultRemoteMeetingType, remoteMeetingType]);

  const schema = z.object({
    link: z.string(),
    linkName: z.string().min(2, { message: 'Link name should have at least 2 letters' }),
  });

  const form = useForm({
    initialValues: {
      link: initialPreferences.link || '',
      linkName: initialPreferences.linkName || '',
    },
    validate: zodResolver(schema),
  });

  const handleLinkTypeChange = (value: string | null) => {
    if (!value || !initialPreferences) {
      // This should never happen, throwing an error
      throw new Error('Value or preferences not found');
    }

    const valueLinkType = DISPLAY_NAME_TO_LINK_TYPE[value];
    setLinkType(valueLinkType);

    if (valueLinkType !== LinkType.CustomLink && valueLinkType !== LinkType.Zoom) {
      updatePreferences({ ...initialPreferences, linkType: valueLinkType, zoomAccountId: null });
      return;
    }
  };

  const handleSubmit = (values: typeof form.values) => {
    if (!linkType || !initialPreferences) {
      return;
    }
    updatePreferences({
      ...initialPreferences,
      link: values.link,
      linkName: values.linkName,
      linkType: linkType,
    });
  };

  const handleDefaultRemoteMeetingTypeChange = (value: RemoteMeetingType | null) => {
    if (!value || !initialPreferences) {
      // This should never happen, throwing an error
      throw new Error('Value or preferences not found');
    }

    setRemoteMeetingType(value);
    updatePreferences({ ...initialPreferences, defaultRemoteMeetingType: value });
  };

  const handleZoomAccountChange = (value: string | null) => {
    if (!value || !initialPreferences) {
      // This should never happen, throwing an error
      throw new Error('Value or preferences not found');
    }

    updatePreferences({ ...initialPreferences, linkType: LinkType.Zoom, zoomAccountId: value });
  };

  if (!linkType) return null;

  return (
    <Stack>
      <SettingsGrid
        Icon={IconDeviceDesktop}
        title="Virtual Meetings"
        description="Choose your preferred method for virtual meetings."
        left={<></>}
      />
      <Text c="dimmed">
        Please check a default virtual meeting format. Blockit will default to this unless specified otherwise on your
        email thread.
      </Text>

      <Radio.Group
        value={remoteMeetingType}
        onChange={(value) => handleDefaultRemoteMeetingTypeChange(value as RemoteMeetingType)}
      >
        <Radio
          value={RemoteMeetingType.Video}
          disabled={false}
          label={
            <Stack style={{ marginBottom: '10px' }} gap="sm">
              <Text lh={'normal'} span>
                Video meeting
              </Text>
              <Select
                data={Object.keys(DISPLAY_NAME_TO_LINK_TYPE).filter((key) =>
                  isInternalUser ? true : key !== LinkType.Zoom,
                )} // Internal users can select Zoom, external users cannot
                value={LINK_TYPE_TO_DISPLAY_NAME[linkType]}
                onChange={handleLinkTypeChange}
                allowDeselect={false}
                miw={360}
              />
              {linkType === LinkType.CustomLink && (
                <form onSubmit={form.onSubmit(handleSubmit)}>
                  <Stack>
                    <TextInput
                      label="Link Name"
                      placeholder='Title your link (eg: "Zoom")'
                      {...form.getInputProps('linkName')}
                    />
                    <TextInput label="URL Link" placeholder="https://zoom.us/..." {...form.getInputProps('link')} />
                    {(form.isDirty() || linkType !== initialPreferences.linkType) && (
                      <Button loading={isUpdatingPreferences} type="submit">
                        Save
                      </Button>
                    )}
                  </Stack>
                </form>
              )}
              {linkType === LinkType.Zoom && (
                <ZoomAccountSelector
                  initialZoomAccountId={initialPreferences.zoomAccountId}
                  onZoomAccountChange={handleZoomAccountChange}
                  isDirty={linkType !== initialPreferences.linkType}
                />
              )}
            </Stack>
          }
        />
        <Radio
          value={RemoteMeetingType.Phone}
          disabled={false}
          label={
            <>
              <Text span lh={'normal'} mr={10}>
                Phone call
              </Text>
              <Text span lh={'normal'} c="dimmed">
                Blockit will ask the recipient for their phone number and add it to the location field.
              </Text>
            </>
          }
        />
      </Radio.Group>
    </Stack>
  );
};
