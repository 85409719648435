import React from 'react';

import { Box, Divider, Paper, Stack, Text } from '@mantine/core';

import { useInSchedulingMeetings } from '../api/getInSchedulingMeetings';
import { InSchedulingMeeting } from '../types';

import { InSchedulingItem } from './InSchedulingItem';

export const InSchedulingList: React.FC = () => {
  const { data: meetings } = useInSchedulingMeetings();

  if (!meetings || meetings.length === 0) return null;

  const meetingsWaitingForSidechainResponse = meetings.filter((meeting) => meeting.isWaitingForSidechainResponse);
  const meetingsNotWaitingForSidechainResponse = meetings.filter((meeting) => !meeting.isWaitingForSidechainResponse);
  return (
    <Box mt="md">
      {meetings.length === 0 ? (
        <Text>No meetings are currently being scheduled.</Text>
      ) : (
        <Stack gap="md">
          {meetingsWaitingForSidechainResponse.length > 0 && (
            <>
              <Text fw={700}>Waiting on you</Text>
              <Paper withBorder p="xs" bg="yellow.0" radius="md">
                <Stack gap="xs">
                  {meetingsWaitingForSidechainResponse.map((meeting: InSchedulingMeeting, index: number) => (
                    <React.Fragment key={meeting.id}>
                      <InSchedulingItem meeting={meeting} />
                      {index < meetingsWaitingForSidechainResponse.length - 1 && <Divider />}
                    </React.Fragment>
                  ))}
                </Stack>
              </Paper>
              <Text fw={700}>Waiting on others</Text>
            </>
          )}
          {meetingsWaitingForSidechainResponse.length === 0 && (
            <Text size="lg" fw={700}>
              In Scheduling
            </Text>
          )}
          <Stack gap="xs">
            {meetingsNotWaitingForSidechainResponse.map((meeting: InSchedulingMeeting, index: number) => (
              <React.Fragment key={meeting.id}>
                <InSchedulingItem meeting={meeting} />
                {index < meetingsNotWaitingForSidechainResponse.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </Stack>
        </Stack>
      )}
    </Box>
  );
};
