import { Stack } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconClockHour10 } from '@tabler/icons-react';

import { AnalyticsEventName, track } from '@features/analytics';

import { useUpdatePreferences } from '../api/updatePreferences';
import { PreferenceSettings } from '../types';

import { MinutesSelection } from './MinutesSelection';
import { SettingsGrid } from './SettingsGrid';

export const MeetingDuration = ({ initialPreferences }: { initialPreferences: PreferenceSettings }) => {
  const { mutate: updatePreferences } = useUpdatePreferences({
    onSuccess: () => {
      track({
        type: AnalyticsEventName.PreferencesUpdated,
        data: { preference: 'duration', isDefault: initialPreferences.isDefault },
      });
      notifications.show({ message: 'Meeting duration updated', color: 'green' });
    },
  });

  const handleDurationSelect = (value: number) => {
    updatePreferences({ ...initialPreferences, duration: value });
  };

  const handleInPersonDurationSelect = (value: number) => {
    updatePreferences({ ...initialPreferences, inPersonDuration: value });
  };

  return (
    <SettingsGrid
      Icon={IconClockHour10}
      title="Meeting Duration"
      description="We will use this duration for your meetings unless otherwise specified."
      left={
        <Stack>
          <MinutesSelection label="Remote" initialValue={initialPreferences.duration} onSelect={handleDurationSelect} />
          <MinutesSelection
            label="In-person"
            initialValue={initialPreferences.inPersonDuration}
            onSelect={handleInPersonDurationSelect}
          />
        </Stack>
      }
    />
  );
};
