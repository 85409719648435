import { FC } from 'react';

import { Link } from 'react-router-dom';

import { RouteDetails } from '@routes/constants';
import { PRIVATE_ROUTES } from '@routes/enums';

import { NavLink } from './NavLink';
import { TooltipActionIcon } from './TooltipActionIcon';

interface NavLinkGroupProps {
  routes: RouteDetails[];
  isCollapsed?: boolean;
  activeLink?: PRIVATE_ROUTES;
  onNavigate?: () => void;
}

export const NavLinkGroup: FC<NavLinkGroupProps> = ({ routes, isCollapsed, activeLink, onNavigate }) => {
  return (
    <>
      {routes.map((details) => {
        const IconComponent = details.icon;
        const ActiveIconComponent = details.activeIcon;

        const iconSize = isCollapsed ? 48 : 20;

        const isActive = details.path === activeLink;

        const icon = isActive ? (
          ActiveIconComponent ? (
            <ActiveIconComponent size={iconSize} stroke={0} />
          ) : (
            <IconComponent size={iconSize} stroke={2.5} />
          )
        ) : (
          <IconComponent size={iconSize} stroke={1.5} />
        );

        if (isCollapsed) {
          return (
            <TooltipActionIcon
              key={details.path}
              component={Link}
              to={details.path}
              title={details.title}
              icon={icon}
              onClick={onNavigate}
            />
          );
        }

        return (
          <NavLink
            key={details.path}
            label={details.title}
            to={details.path}
            active={isActive}
            leftSection={icon}
            onClick={onNavigate}
          />
        );
      })}
    </>
  );
};
