import { Box, Stack, Text, Title } from '@mantine/core';

import { SideContent } from '@components/SideContent';

import { usePreferences } from '../api/getPreferences';
import { useUpdatePreferences } from '../api/updatePreferences';
import { Availability } from '../types';

import { AvailabilityCalendar } from './AvailabilityCalendar';
import { WorkingHoursForm } from './WorkingHoursForm';

export const AvailabilityPage = () => {
  const { data: preferences } = usePreferences();
  const { mutate: updatePreferences, isPending: isPreferencesUpdating } = useUpdatePreferences();

  if (!preferences) {
    return null;
  }
  const updateAvailability = (availability: Availability) => {
    updatePreferences({ ...preferences, availability });
  };

  return (
    <SideContent
      sideContentSize="300"
      sideContent={
        <Stack>
          <Box>
            <Title order={4}>Working hours</Title>
            <Text c="dimmed" size="sm">
              We will never propose times outside of your working hours.
            </Text>
          </Box>
          <WorkingHoursForm />
        </Stack>
      }
    >
      <AvailabilityCalendar
        initialAvailability={
          preferences.availability || {
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: [],
          }
        }
        updateAvailability={updateAvailability}
        isUpdating={isPreferencesUpdating}
      />
    </SideContent>
  );
};
